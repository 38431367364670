import { Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./niche-pig-loader.css";
import pig from "./pig.png";

const NichePigLoading = (props: any) => {
  const [index, setIndex] = useState(0);
  const messages = [
    {
      title: "Extracting Data From Search Engines...",
      description: "Niche Pig Is Extracting Search Data From Live Sources...",
    },
    {
      title: "Analyzing Buyer Sentiment and Online Reviews...",
      description: "Niche Pig Is Analyzing Buyer Sentiment From Online Sources...",
    },
    {
      title: "Scraping Profitable Products And Services Online...",
      description: "Niche Pig Is Scraping For Existing Profitable Products & Services Sold Online...",
    },
    {
      title: "Compiling Data And Finalizing Report...",
      description: "Almost Done Finalizing the Report...",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          px: 5,
          py: 14,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack>
          <Stack className="pig-loader" sx={{ py: 2 }}>
            <img src={pig} width="50" alt="Loading" />
          </Stack>
        </Stack>
        <Typography align="center" variant="h3" sx={{ pt: 2 }}>
          {messages[index].title}
        </Typography>
        <Typography align="center" color="text.secondary" variant="body2">
          {messages[index].description}
        </Typography>
      </Container>
    </>
  );
};

export default NichePigLoading;
