import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExploreIcon from "@mui/icons-material/Explore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  CircularProgress,
  Container,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import axios from "axios";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../../core/store/store";
import { ToolLeadData } from "../../../interfaces/toolData.interface";
import MaterialMarkdown from "../material-markdown";
import NichePigLoading from "@wordform/features/tools/niche-pig/niche-pig-loading";
import NichePigResultsTable from "@wordform/features/tools/niche-pig/results/results-table";
import { NicheJobData } from "@wordform/features/tools/niche-pig/niche-pig.slice";
import { set } from "lodash";

interface FormInputs {
  name: string;
  email: string;
  interest: string;
}

const NicheExplorer2: React.FC = () => {
  const [searchParams] = useSearchParams();
  const backgroundColor = searchParams.get("bg_color");
  const buttonColor = searchParams.get("button_color");
  const headlineDisabled = searchParams.get("headline_disabled");
  const origin = searchParams.get("origin") ?? "*";
  const interestName = searchParams.get("keyword") ?? "";

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormInputs>();
  const [loading, setLoading] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);
  const [content, setContent] = useState<{ data: any } | null>(null);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const [formError, setFormError] = useState("");

  const fetchContent = async (key: string): Promise<boolean> => {
    try {
      const response: any = await axios.post(`${apiUrl}/embed/get-lead`, {
        key,
      });
      const dataResp: ToolLeadData = response.data.result.tool;
      setContent({ data: { niches: dataResp.data.niches } });
      return dataResp.data.finished as boolean;
    } catch (e: any) {
      console.warn(e);
      return false;
    }
  };

  const getResponse: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    try {
      setLoading(true);
      setContent(null);
      setFinished(false);

      const response: any = await axios.post(`${apiUrl}/embed/niche-explorer-2`, data, { timeout: 60 * 1000 });

      const dataResp: ToolLeadData = response.data.result.tool;

      // Now setting up the interval to call fetchContent every 3 seconds

      const checkContent = async () => {
        const isFinished = await fetchContent(dataResp.key as string);
        setFinished(isFinished);
        if (!isFinished) {
          setTimeout(checkContent, 3000);
        } else {
          window.top?.postMessage("gotResponse", origin);
          setLoading(false);
        }
      };
      await checkContent();
    } catch (e: any) {
      console.warn(e);
      setLoading(false);
      window.top?.postMessage("gotError", origin);
      if (e.response?.data?.error?.message) {
        // Set custom error on a specific field, for example 'username'
        setFormError(e.response.data.error.message);
      } else if (e.request) {
        setFormError("Network error occurred...");
      } else {
        setFormError("Network error occurred...");
      }
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    window.top?.postMessage("onSubmit", origin);
    getResponse(data);
    setContent(null);
    setFinished(false);
  };

  const handleBack = () => {
    window.top?.postMessage("handleBack", origin);
    setContent(null);
    setFinished(false);
    setLoading(false);
  };

  document.body.style.backgroundColor = backgroundColor ? backgroundColor : "white";

  const CssTextField = withStyles({
    root: buttonColor
      ? {
          "& label.Mui-focused": {
            color: buttonColor,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: buttonColor,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {},
            "&:hover fieldset": {},
            "&.Mui-focused fieldset": {
              borderColor: buttonColor,
            },
          },
        }
      : {},
  })(TextField);

  if (loading && !finished) {
    return (
      <Box
        sx={{
          position: "absolute", // or use 'fixed' if you want it to stay in the same place when scrolling
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NichePigLoading />
      </Box>
    );
  }

  if (content && finished) {
    return (
      <Box>
        <Box display="flex" alignItems="center" mb={2} onClick={handleBack} sx={{ "&:hover": { cursor: "pointer" } }}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Start New Search</Typography>
        </Box>
        <Stack sx={{ my: 2 }}>{<NichePigResultsTable hideButtons={true} toolData={content as NicheJobData} />}</Stack>
        <Box display="flex" alignItems="center" mb={2} onClick={handleBack} sx={{ "&:hover": { cursor: "pointer" } }}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Start New Search</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: "5px",
      }}
    >
      <Box my={4}>
        {!headlineDisabled && (
          <>
            <Typography variant="h4" component="h1" gutterBottom>
              Find Low-Competition Blog Niches
            </Typography>
            <Typography variant="body1" gutterBottom>
              Enter your interest to discover your next low-competition blog niche.
            </Typography>
          </>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {formError && <FormHelperText error>{formError}</FormHelperText>}

          <CssTextField
            {...register("interest", { required: true })}
            label="Interest Name"
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={loading}
            error={!!errors.interest}
            helperText="Enter a hobby, passion, or subject matter you wish to explore."
            defaultValue={interestName !== "" ? interestName : ""}
          />
          <Box mt={2} mb={4}>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="primary"
              type="submit"
              startIcon={!loading && <ExploreIcon />}
              disabled={loading}
              sx={{
                padding: "10px 22px",
                ".MuiLoadingButton-loadingIndicator": {
                  marginRight: "10px", // Add padding to the right of the loader
                },
                backgroundColor: buttonColor ? buttonColor : undefined,
                "&:hover": {
                  backgroundColor: (theme) => (buttonColor ? alpha(buttonColor, 0.8) : undefined),
                },
              }}
            >
              Generate Niches
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default NicheExplorer2;
