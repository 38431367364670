import { Box, Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../core/hooks/useStore";
import { RootState } from "../../../../core/store/store";
import NichePigLogo from "../niche-pig-logo.png";
import NichePigResultsTable from "./results-table";
import { NicheJobData } from "../niche-pig.slice";

const NichePigResultsPDF = (props: any) => {
  const dispatch = useAppDispatch();
  const toolData = useSelector((state: RootState) => state.nichePig);
  const currentDate = new Date().toLocaleDateString();
  return (
    <>
      {" "}
      <Stack sx={{ padding: 4, margin: 2, backgroundColor: "#fff" }}>
        <Stack flexDirection="row" alignItems="center">
          <Box>
            <Stack flexDirection="row" alignItems="baseline">
              <Typography variant="h4" component="h4" sx={{ fontWeight: "bold" }}>
                Niche Analysis Report
              </Typography>
              <Typography variant="subtitle1" sx={{ px: 3 }}>
                {currentDate}
              </Typography>
            </Stack>
            <Typography variant="subtitle1" sx={{ marginBottom: 2, color: "gray" }}>
              This is a real niche assessment considering various market factors.
            </Typography>
          </Box>
          <div style={{ flexGrow: 1 }} />
          <Box sx={{ mx: 2, my: 2 }}>
            <img src={NichePigLogo} alt="Niche Pig Pro" width={"200px"} />
          </Box>
        </Stack>
        <Divider
          sx={(theme) => {
            return {
              height: "3px",
              mb: 3,
              width: "100%",
              background: theme.palette.primary.main,
            };
          }}
        />
        <Typography
          variant="h5"
          component="h5"
          sx={(theme) => {
            return {
              color: theme.palette.primary.main,
              my: 3,
            };
          }}
        >
          Search Term: {toolData.tool.data?.data.interest}
        </Typography>
        <NichePigResultsTable hideButtons toolData={toolData.tool.data as NicheJobData} />
      </Stack>
    </>
  );
};

export default NichePigResultsPDF;
