import {
  Button,
  Box,
  Table,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { PropertyListItem } from "@wordform/common/components/property-list-item";
import DashboardLayout from "@wordform/common/layouts/dashboard";
import { useAppDispatch } from "@wordform/core/hooks/useStore";
import { RootState } from "@wordform/core/store/store";
import usePaddlePlans from "./use-paddle";
import { useEffect } from "react";
import { useDialog } from "@wordform/hooks/use-dialog";
import { CancelSubscriptionDialog } from "./cancel-subscription-dialog";
import { ActivateAddonDialog } from "./activate-addon-dialog";
import { PaddleSubscription, PaddleAvailableAddon } from "./paddle.slice";

interface SubscriptionTableProps {
  activeSubs: PaddleSubscription[];
  cancelSubDialog: any;
  manageSubscriptionUrl?: string;
}
const PlansTable: React.FC<SubscriptionTableProps> = ({ activeSubs, cancelSubDialog, manageSubscriptionUrl }) => {
  return (
    <Card>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: "300px" }}>Plan</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell style={{ minWidth: "130px" }}>Subscribed</TableCell>
              <TableCell style={{ minWidth: "130px" }}>Next Bill</TableCell>
              <TableCell style={{ width: "90%" }}>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeSubs.map((row, index) => (
              <TableRow key={"plans-table-" + row.priceId}>
                <TableCell style={{ minWidth: "300px" }}>{row.name}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell style={{ minWidth: "130px" }}>{row.subscribed}</TableCell>
                <TableCell style={{ minWidth: "130px" }}>{row.nextBilledAt}</TableCell>
                <TableCell style={{ width: "90%" }}>&nbsp;</TableCell>
                <TableCell>
                  {row.nextBilledAt !== "" && (
                    <Box display="flex" justifyContent="flex-end">
                      {manageSubscriptionUrl && (
                        <Button href={manageSubscriptionUrl} target="_blank" variant="text">
                          manage
                        </Button>
                      )}
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

const AddonsTable: React.FC<SubscriptionTableProps> = ({ activeSubs, cancelSubDialog }) => {
  return (
    <Card>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: "300px" }}>Addon</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell style={{ width: "90%" }}>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeSubs.map((row, index) => (
              <TableRow key={"addons-table-" + row.priceId}>
                <TableCell style={{ minWidth: "300px" }}>{row.name}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell style={{ width: "90%" }}>&nbsp;</TableCell>
                <TableCell>
                  {row.amount !== "$0/month" && (
                    <Box display="flex" justifyContent="flex-end">
                      <Button onClick={() => cancelSubDialog.handleOpen(row)} variant="text">
                        cancel
                      </Button>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

interface AvailableAddonsGridProps {
  availableAddons: PaddleAvailableAddon[];
  activateAddonDialog: any;
}

const AvailableAddonsGrid: React.FC<AvailableAddonsGridProps> = ({ availableAddons, activateAddonDialog }) => {
  return (
    <Box display="flex" py={4}>
      <ActivateAddonDialog
        onClose={activateAddonDialog.handleClose}
        open={activateAddonDialog.open}
        addon={activateAddonDialog.data}
      />

      <Grid container spacing={2}>
        {availableAddons.map((row, _) => {
          const { title, description, price, buttonLabel } = {
            title: row.name,
            description: row.description.split("\n"),
            price: row.amount,
            buttonLabel: "Buy",
          };

          return (
            <Grid item xs={12} sm={6} lg={4} key={"upgrade-options-" + row.priceId} maxWidth={600}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="center">
                    {title}
                  </Typography>
                  <Divider style={{ margin: "10px 0" }} />
                  {description.map((line, index) => (
                    <Typography key={line} variant="body2" align="center">
                      {line}
                    </Typography>
                  ))}
                  <Typography variant="h5" align="center" style={{ margin: "20px 0" }}>
                    {price}
                  </Typography>
                  <Box display="flex" justifyContent="center">
                    <Button onClick={() => activateAddonDialog.handleOpen(row)} variant="outlined">
                      {buttonLabel}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const PaddleView = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const paddleState = useSelector((state: RootState) => state.paddle);
  const [loading, setLoading] = useState<boolean>(false);
  const { getPlans } = usePaddlePlans();
  const cancelSubDialog = useDialog();
  const activateAddonDialog = useDialog();
  const showAddons = paddleState.paddle.activeAddons.length > 0 || paddleState.paddle.availableAddons.length > 0;
  const manageSubscriptionUrl = paddleState.paddle.manageSubscriptionUrl;

  useEffect(() => {
    const fetchPlans = async () => {
      if (authState.user?.id) {
        setLoading(true);
        await getPlans();
        setLoading(false);
      }
    };
    fetchPlans();
  }, [authState.user?.id]);

  if (loading) {
    return (
      <DashboardLayout active={"billing"} name="Billing">
        <Box sx={{ width: "100%" }}>
          <Typography variant="h4" sx={{ pt: 2 }}>
            <Skeleton variant="text" />
          </Typography>
          <Skeleton variant="rectangular" height={118} />
          <Typography variant="h4" sx={{ pt: 10 }}>
            <Skeleton variant="text" />
          </Typography>
          <Skeleton variant="rectangular" height={118} />
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout active={"billing"} name="Billing">
      <CancelSubscriptionDialog
        onClose={cancelSubDialog.handleClose}
        open={cancelSubDialog.open}
        sub={cancelSubDialog.data}
      />
      <Stack spacing={3}>
        <Typography variant="h4" sx={{ pt: 2 }}>
          Plan
        </Typography>

        {paddleState.paddle.activePlans.length > 0 ? (
          <PlansTable
            activeSubs={paddleState.paddle.activePlans}
            cancelSubDialog={cancelSubDialog}
            manageSubscriptionUrl={manageSubscriptionUrl}
          />
        ) : (
          <Typography variant="body2" sx={{ pt: 2 }}>
            You don't have any active plans.
          </Typography>
        )}

        {showAddons && (
          <>
            <Typography variant="h4" sx={{ pt: 2 }}>
              Addons
            </Typography>
            {paddleState.paddle.activeAddons.length > 0 ? (
              <AddonsTable activeSubs={paddleState.paddle.activeAddons} cancelSubDialog={cancelSubDialog} />
            ) : (
              <Typography variant="body2" sx={{ pt: 2 }}>
                You don't have any active addons.
              </Typography>
            )}
          </>
        )}

        {paddleState.paddle.availableAddons.length > 0 && (
          <AvailableAddonsGrid
            availableAddons={paddleState.paddle.availableAddons}
            activateAddonDialog={activateAddonDialog}
          />
        )}
      </Stack>
    </DashboardLayout>
  );
};

export default PaddleView;
