import { Navigate, Route, Routes } from "react-router-dom";

// Pages
import Account from "../../features/account";
import Login from "../../features/auth/login";
import Recover from "../../features/auth/recover/recover";
import Reset from "../../features/auth/reset";
import Billing from "../../features/billing";
import Editor from "../../features/editor";
import HeadlineGenerator from "../../features/embed/tools/headline-generator";
import KeywordGenerator from "../../features/embed/tools/keyword-generator";
import NicheExplorer from "../../features/embed/tools/niche-explorer";
import NicheExplorer2 from "../../features/embed/tools/niche-explorer-2";
import Error from "../../features/error";
import Home from "../../features/home";
import Integrations from "../../features/integrations";
import Projects from "../../features/projects";
import Share from "../../features/share/share";
import Tools from "../../features/tools";
import HeadlinePig from "../../features/tools/headline-pig/headline-pig";
import KeywordPig from "../../features/tools/keyword-pig";
import NichePig from "../../features/tools/niche-pig";
import Training from "../../features/training";
import RequireAuth from "./requireAuth";

function Routing() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/recover/:email" element={<Recover />} />
        <Route path="/recover/:email/:code" element={<Recover />} />
        <Route element={<RequireAuth />}>
          <Route path="/projects" element={<Projects />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/editor/:id" element={<Editor />} />
          <Route path="/account/update" element={<Account />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/training" element={<Training />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/tools/niche-tool" element={<NichePig />} />
          <Route path="/tools/keyword-tool" element={<KeywordPig />} />
          <Route path="/tools/headline-tool" element={<HeadlinePig />} />
        </Route>
        <Route path="/share/:id" element={<Share />} />
        <Route path="/embed/niche-explorer" element={<NicheExplorer />} />
        <Route path="/embed/niche-explorer-2" element={<NicheExplorer2 />} />
        <Route path="/embed/keyword-generator" element={<KeywordGenerator />} />

        <Route path="/tools/headline-generator" element={<HeadlineGenerator />} />
        <Route path="/error/unauthorized" element={<Error status={401} message="Unauthorized request" />} />
        <Route path="/error/notfound" element={<Error status={404} message="Page not found" />} />
        <Route path="*" element={<Navigate to="/error/notfound" replace />} />
      </Routes>
    </div>
  );
}

export default Routing;
