import { Box, Container, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../core/store/store";
import NichePigResultsTable from "./results-table";
import { NicheJobData } from "../niche-pig.slice";

const NichePigResults = (props: any) => {
  const toolData = useSelector((state: RootState) => state.nichePig);
  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          px: 5,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            "& img": {
              maxWidth: "100%",
            },
          }}
        />

        <Typography align="center" variant="h3" sx={{ pt: 2 }}>
          Your Results Are In!
        </Typography>
        <Typography align="center" color="text.secondary" variant="body2">
          Niche Pig Pro Has Successfully Found Several Profitable Niches For You!
        </Typography>
        <Stack sx={{ my: 2 }}>
          {toolData.tool.type === "NICHE" && <NichePigResultsTable toolData={toolData.tool.data as NicheJobData} />}
        </Stack>
      </Container>
    </>
  );
};

export default NichePigResults;
